import React, { useState, useEffect } from "react"
import Gfx from '../gfx/index';
import Form from '../components/form'
import { Helmet } from "react-helmet";
import Div100vh from 'react-div-100vh'
import Video from '../components/video';
import Popup from '../components/popup';
import Footer from '../components/footer';
import top from '../style/top_popup.png'
import bottom from '../style/bottom_popup.png'
import badge from '../style/badge.png'
import stream from '../style/stream.jpg'
import loaderVid from '../style/video.mp4'
import OG from '../style/og-image.jpg';
import '../style/style.scss';


export default function Home() {
  const arr = []
  const [loading, setLoad] = useState(false);
  const [videoDone, setDone] = useState(false);

  const onPlay = (error) => {
    setLoad(true);
    if (typeof document !== 'undefined') {
      if (!error) {
        setTimeout(function(){
          document.querySelector('.video-container.loading').classList.add('fader');      
        }, 4000);
      } else {
        document.querySelector('.video-container.loading').classList.add('fader');  
      }
    }
  }


  useEffect(() => {
    // Update the document title using the browser API
    if (typeof document !== 'undefined') {
      document.addEventListener("contextmenu", function(e){
        e.preventDefault();
      }, false);
      document.querySelectorAll('img').forEach(element => {
        element.ondragstart = function() { return false; };
      });
    }
  });
  return (
    <Div100vh>
      <Helmet
        title={'bladee & Mechatok’s GOOD LUCK'}
        meta={[
          {
            name: 'apple-mobile-web-app-capable',
            content: "yes"
          },
          {
            name: `description`,
            content: `Extreme Good luck frequency #9DExperience Attract good luck TODAY!!! Unexpected rewards (It Really Works)`,
          },
          {
            property: `og:title`,
            content: 'bladee & Mechatok’s GOOD LUCK',
          },
          {
            property: `og:description`,
            content: 'Extreme Good luck frequency #9DExperience Attract good luck TODAY!!! Unexpected rewards (It Really Works)',
          },
          {
            property: `og:image`,
            content: `${OG}`,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:image:src`,
            content: `https://goodluck.gtb.sg${OG}`,
          },
          {
            name: `twitter:title`,
            content: 'bladee & Mechatok’s GOOD LUCK',
          },
          {
            name: `twitter:description`,
            content: 'Extreme Good luck frequency #9DExperience Attract good luck TODAY!!! Unexpected rewards (It Really Works)',
          },
        ]}
      >
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
        />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-VFFS3N494P"></script>
        <script dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-VFFS3N494P');`
        }}>
          
        </script>
      </Helmet> 
      <div className="wrapper">
      </div>
      <div style={{opacity: loading ? 1 : 0}} className="content animate__animated animate__fadeIn animate__delay-2s">
        
        <Video 
          onPlay={onPlay} 
          video={'https://vimeo.com/482741113/5e25522944'}
          videom={'https://vimeo.com/482741942/33bfe55b8d'}
        />
        <Popup className="signup">
          <a href="https://www.momenthouse.com/co/goodluck?ref=[website]" tagret="_blank"> 
            <img className="top" src={top} alt=""/>
          </a>
          <img className="badge" src={badge} alt=""/>
          <Form />
          <a href="https://www.momenthouse.com/co/goodluck?ref=[website]" tagret="_blank">
            <img className="bottom" src={bottom} alt=""/>
          </a>
        </Popup>
        <Popup className="stream">
          <a href="https://www.momenthouse.com/co/goodluck?ref=[website]" tagret="_blank"> 
            <img className="stream_img" src={stream} alt=""/>
          </a>
        </Popup>
        <Footer />
      </div>
      <Video
        loader
        loaderClass={videoDone}
        onPlay={onPlay} 
        video={loaderVid}
      />
      <div className={"loader " + (loading ? 'fader' : '')} style={{
        position: 'fixed', 
        width: '100vw', 
        height: '100vh', 
        background: '#02ff12', 
        top: 0, 
        left: 0, 
        zIndex: 999,
      }}>
        LOADING
      </div>
    </Div100vh>
  )
}
