import React, { useState } from 'react'
import close from '../style/close.png'

const Popup = ({className, children}) => {
    const [open, setOpen] = useState(true);

    const closeHandler = () => {
        setOpen(false); 

        setTimeout(function(){ setOpen(true)}, 10000);
    } 
    return (
        <div className={`popup ${className} ${open ? '' : 'hide'}`}>
            <div className="close" onClick={closeHandler}><img src={close} alt=""/></div>
            {children}
        </div>
    );
}

export default Popup;