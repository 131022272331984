import React from 'react'
import dice from '../style/dice.png'
import bar from '../style/bar.png'
import logo from '../style/logo.png'

const Footer = () => {

    return (
        <div className={`footer`}>
            <div>
                <img className={`bar`} src={bar} alt=""/>
            </div>
            <div>
                <img className={`dice`} src={dice} alt=""/>
            </div>
            <div>
                <img className={`logo`} src={logo} alt=""/>
            </div>
        </div>
    );
}

export default Footer;