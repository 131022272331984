import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { useMediaQuery } from 'react-responsive'

const Video = ({speed, onPlay, loader, video, videom, loaderClass}) => {
    const [playing, setPlaying] = useState(true);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const mobileVid = videom ? videom : video;  

    const startHandler = () => {
        if (loader) {
            onPlay(false);
        }
    }

    const errorHandler = () => {
        console.error('Cant play bg video');
        setPlaying(false);
        onPlay(true);
    }

    return (
        <ReactPlayer 
            onError={errorHandler} 
            onPlay={startHandler} 
            playsinline
            className={`video-container ${loader ? 'loading' : ''}`}
            playbackRate={speed} 
            loop={true} 
            url={isMobile ? mobileVid : video} 
            playing={true} 
            volume={0}/>
    );
}

export default Video;