import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import e from '../style/e.png';

export default class Form extends React.Component {
  state = {
      email: '',
      message: ''
  }
  _handleSubmit = e => {
    const { email } = this.state
    e.preventDefault();
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
    .then(data => {
      // I recommend setting data to React state
      // but you can do whatever you want (including ignoring this `then()` altogether)
      if(data.result === "success"){
        this.setState({message: 'Good Luck'})
      }else {
        this.setState({message: 'Try Again'})
      }
    })
    .catch(() => {
      // unnecessary because Mailchimp only ever
      // returns a 200 status code
      // see below for how to handle errors
    })
  }

  render () {
    const { email, message } = this.state
    return (
      <form onSubmit={(e) => this._handleSubmit(e, email)}>
        <div className="flex">
        <input placeholder="Your email here please" onChange={(e) => this.setState({email: e.target.value})} value={email} type="email"/>
        <button onClick={(e) => this._handleSubmit(e, email)}>Send</button>
        </div>
        <div className="message">{message}</div>
      </form>
    )
  }
}